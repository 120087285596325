.plans-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .plans-description {
    margin-bottom: 2rem;
    color: var(--text-color);
  }
  
  .plans-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
  }
  
  .plan-card {
    flex: 1;
    min-width: 250px;
    max-width: 350px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .plan-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .plan-card.featured {
    border: 2px solid var(--accent-color);
    position: relative;
  }
  
  .plan-card.featured::before {
    content: 'Most Popular';
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--accent-color);
    color: white;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  .plan-card h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .price {
    font-size: 2rem;
    color: var(--secondary-color);
    margin-bottom: 1rem;
  }
  
  .amount {
    font-weight: bold;
  }
  
  .period {
    font-size: 1rem;
    color: var(--text-color);
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 2rem;
  }
  
  li {
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }
  
  .select-plan-btn {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .select-plan-btn:hover {
    background-color: var(--secondary-color);
  }
  
  .login-to-select-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-weight: bold;
  }
  
  .login-to-select-btn:hover {
    background-color: var(--secondary-color);
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .plans-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .plan-card {
      width: 100%;
      max-width: 100%;
    }
  }
  
  .plans-triangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .plans-bottom-row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .plan-card {
    width: 300px;
    margin: 10px; 
  }
  
  .plans-login-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .plans-login-container .login-button,
  .plans-login-container .login-to-select-btn {
    width: 100%;
    max-width: 200px;
  }

  .small-login-button {
    font-size: 0.9rem;
    padding: 8px 16px;
    width: auto;
    min-width: 120px;
    max-width: 180px;
  }

  .try-now-btn {
    background-color: #4CAF50;  /* A green color to signify action */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .try-now-btn:hover {
    background-color: #45a049;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    transform: translateY(-2px);
  }
  
  .try-now-btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  .current-plan-btn,
  .change-plan-btn {
    width: 100%;
    padding: 10px 20px;
    border-radius: 25px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  .current-plan-btn {
    background-color: #4CAF50;  /* Green color for current plan */
    color: white;
    border: none;
    opacity: 0.8;  /* Slightly faded to indicate it's not clickable */
  }

  .current-plan-btn:disabled {
    cursor: not-allowed;
  }

  .change-plan-btn {
    background-color: var(--accent-color);
    color: white;
    border: none;
  }

  .change-plan-btn:hover {
    background-color: var(--secondary-color);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    transform: translateY(-2px);
  }

  .change-plan-btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .plans-container.in-modal {
    padding: 0;
  }

  .plans-container.in-modal .plans-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  .plans-container.in-modal .plan-card {
    padding: 1rem;
  }

  .plans-container.in-modal h2 {
    font-size: 1.2rem;
  }

  .plans-container.in-modal .price .amount {
    font-size: 1.5rem;
  }

  .plans-container.in-modal ul {
    font-size: 0.9rem;
  }