.console-container {
    background-color: #f0f4f8;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .console-title {
    color: #2c3e50;
    margin-bottom: 24px;
  }
  
  .notification-paper {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .form-button {
    border-radius: 20px;
    padding: 8px 16px;
    text-transform: none;
    font-weight: 600;
  }
  
  .add-button {
    background-color: #3498db;
  }
  
  .add-button:hover {
    background-color: #2980b9;
  }
  
  .notification-item {
    border-radius: 8px;
    margin-bottom: 16px;
    transition: all 0.3s ease;
  }
  
  .notification-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .edit-button {
    color: #3498db;
  }
  
  .delete-button {
    color: #e74c3c;
  }

  .contact-support-button {
    background-color: #607d8b !important; /* A grayish-blue color */
    color: white !important;
  }

  .contact-support-button:hover {
      background-color: #546e7a !important; /* A slightly darker shade for hover state */
  }