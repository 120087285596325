.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--card-background);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.privacy-policy-container h4 {
    color: var(--primary-color);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 300;
}

.privacy-policy-container h6 {
    color: var(--secondary-color);
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 400;
}

.privacy-policy-container p {
    color: var(--text-color);
    line-height: 1.6;
    margin-bottom: 1rem;
}

.privacy-policy-container ul {
    margin-left: 20px;
    margin-bottom: 1rem;
}

.privacy-policy-container li {
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.privacy-policy-container a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.3s ease;
}

.privacy-policy-container a:hover {
    color: var(--secondary-color);
}

@media (max-width: 600px) {
    .privacy-policy-container {
        padding: 15px;
    }

    .privacy-policy-container h4 {
        font-size: 2rem;
    }

    .privacy-policy-container h6 {
        font-size: 1.2rem;
    }
}