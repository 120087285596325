:root {
  --primary-color: #3a7ca5;
  --secondary-color: #16a085;
  --accent-color: #f39c12;
  --background-color: #f5f7fa;
  --card-background: #ffffff;
  --text-color: #2c3e50;
  --text-light: #ecf0f1;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
}

.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: var(--text-light);
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-icon {
  font-size: 2.5rem;
  margin-right: 1rem;
  color: var(--accent-color);
}

h1 {
  font-size: 3rem;
  margin: 0;
  font-weight: 300;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.intro, .how-it-works, .examples, .cta {
  margin-bottom: 4rem;
}

h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
}

.steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.step {
  flex-basis: 30%;
  margin-bottom: 2rem;
}

.step-icon {
  font-size: 3rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.example-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.card {
  flex: 1 1 200px;
  max-width: 250px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  transition: all 0.3s ease;
}

.card h3 {
  margin-top: 0;
  color: #333;
}

.card p {
  color: #666;
}

.example-text {
  font-style: italic;
  color: #0066cc;
  margin-top: 10px;
}

.try-it-button {
  background-color: var(--accent-color) !important;
  color: var(--text-light) !important;
  border: none !important;
  padding: 5px 20px !important;
  font-size: 1.1rem !important;
  border-radius: 25px !important;
  cursor: pointer !important;
  transition: background-color 0.3s, transform 0.3s !important;
  font-weight: 500 !important;
  text-transform: none !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.try-it-button:hover {
  background-color: #e67e22 !important;
  transform: translateY(-2px) !important;
}

.plans-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.try-it-button {
  margin-bottom: 10px;
}

.plans-link {
  font-size: 0.9em;
  text-decoration: underline;
  color: #666;
}

footer {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: var(--text-light);
  padding: 1rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

footer p {
  margin: 0;
}

.privacy-policy-button {
  color: var(--text-light) !important;
  text-transform: none !important;
  font-size: 0.9rem !important;
  padding: 6px 8px !important;
  min-width: 0 !important;
}

@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  footer p {
    margin-bottom: 0.5rem;
  }
}

.examples {
  margin-top: 10px;
}

.example-text {
  font-style: italic;
  color: #666;
  margin: 5px 0;
}

.example-icon {
  margin-right: 5px;
}

.sign-in-button {
  background-color: var(--primary-color) !important;
  color: var(--text-light) !important;
  border: none !important;
  padding: 10px 20px !important;
  font-size: 1.1rem !important;
  border-radius: 25px !important;
  cursor: pointer !important;
  transition: background-color 0.3s, transform 0.3s !important;
  font-weight: 500 !important;
  text-transform: none !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px;
}

.sign-in-button:hover {
  background-color: var(--secondary-color) !important;
  transform: translateY(-2px) !important;
}